import React from "react"
import Helmet from "react-helmet"

import "../shinel.css"
import PosterSlider from "../organisms/PosterSlider"
import BaseTemplate from "./BaseTemplate"

const EventPosterTemplate = ({
  title,
  description,
  ogImagePath,
  posterSlider,
  children,
}) => (
  <BaseTemplate
    hideHeader={true}
    removePadding={true}
    title={title}
    titleTemplate={`%s`}
    description={description}
    image={ogImagePath}
  >
    <Helmet>
      <style>{`body { background-color: black; }`}</style>
    </Helmet>
    <div className={`poster-main-wrapper`}>
      {posterSlider ? <PosterSlider filenames={posterSlider} /> : null}
      <>{children}</>
    </div>
  </BaseTemplate>
)

export default EventPosterTemplate
