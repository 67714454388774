import React from "react"

import EventPosterTemplate from "../../components/templates/EventPosterTemplate"
import PosterImage from "../../components/shinel-poster"
import OutlinkText from "../../components/molecules/OutlinkText"
import ReserveButton from "../../components/molecules/ReserveButton"

const IndexPage = () => (
  <EventPosterTemplate
    title={"연극 〈외투 Шинель〉"}
    description={`2025.1.16-26 혜화동1번지
      서점극장 라블레 제4회 정기공연

      일시 2025. 1. 16 - 26. | 화 - 금 20시 | 토 19시 | 일 16시
      장소 연극실험실 혜화동1번지
      러닝타임 90분
      관람등급 12세 이상
      티켓가격 전석 3만원
      문의 mail@rabelais.kr`}
    ogImagePath={`/shinel-poster2.jpg`}
    posterSlider={[
      `shinel-poster1.jpg`,
      `shinel-poster2.jpg`,
      `shinel-poster3.jpg`,
    ]}
  >
    <PosterImage filename={`shinel-poster4.jpg`} />
    <br />
    <br />
    <ReserveButton
      buttonText={`예매하기`}
      link={`https://forms.gle/jbhicMsh69xPqW4d6`}
    />
    <br />
    <br />
    <p>
      극장 위치
      <br />
      서울 종로구 창경궁로35길 7
      <br />
      <OutlinkText href="https://map.naver.com/p/entry/place/21107009?c=15.00,0,0,0,dh">
        ▶네이버 지도에서 보기
      </OutlinkText>
      <br />
      <OutlinkText href="https://map.kakao.com/?urlX=500301&urlY=1135232&urlLevel=3&itemId=19149691&q=%EC%97%B0%EA%B7%B9%EC%8B%A4%ED%97%98%EC%8B%A4%20%ED%98%9C%ED%99%94%EB%8F%991%EB%B2%88%EC%A7%80&srcid=19149691&map_type=TYPE_MAP">
        ▶카카오맵에서 보기
      </OutlinkText>
      <br />
      주차불가
      <br />
      <br />
      문의 전화{`  `}
      <OutlinkText href="tel:+82-10-6706-9749">010-6706-9749</OutlinkText>
      <br />
      이메일{`  `}
      <OutlinkText href="mailto:mail@rabelais.kr">mail@rabelais.kr</OutlinkText>
      <br />
      <br />
      <br />
      <br />
    </p>
  </EventPosterTemplate>
)

export default IndexPage
