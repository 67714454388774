import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import PosterImage from "../shinel-poster"

const PosterSlider = ({ filenames }) => {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    swipeToSlide: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <>
      <div className="slider-container">
        <Slider {...settings}>
          {filenames.map((filename, index) => (
            <div id={`slider-${index}`}>
              <PosterImage filename={filename} />
            </div>
          ))}
        </Slider>
      </div>
      <div style={{ height: `2rem` }} />
    </>
  )
}

export default PosterSlider
