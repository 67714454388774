import React from "react"

const ReserveButton = ({ buttonText, link, isStrong = true }) => {
  let innerText = buttonText ? buttonText : `신청하기`
  return (
    <p className={`rabelais-button-wrapper`}>
      <a href={link} className={`rabelais-dark-button`}>
        {isStrong === true ? <strong>{innerText}</strong> : innerText}
      </a>
    </p>
  )
}

export default ReserveButton
